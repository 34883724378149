export const PROPERTIESFILETOFIELD = {
  'Import status': 'status',
  'Company Name': 'companyName',
  'Company Number': 'companyNumber',
  'Legal Entity Name': 'legalEntityName',
  'Legal Entity Number': 'legalEntityNumber',
  'Property Number': 'number',
  State: 'address.stateName',
  'Account Number': 'assessorAccounts[0].number',
  Assessor: 'assessorAccounts[0].assessorName',
  'Property Name': 'name',
  'Doing Business As': 'doingBusinessAs',
  'Property Cost Center': 'costCenter',
  'Open Date': 'openDate',
  'Closed Date': 'closedDate',
  'Property GL Account': 'glAccountNumber',
  'Property Description': 'description',
  'Address 1': 'address.line1',
  'Address 2': 'address.line2',
  City: 'address.city',
  'Zip Code': 'address.postalCode',
  'Account Name': 'assessorAccounts[0].name',
  'Alternate Reference': 'assessorAccounts[0].alternateReference',
  'GL Account Number': 'assessorAccounts[0].glAccountNumber',
  'Account Cost Center': 'assessorAccounts[0].costCenter',
  'Legal Description': 'assessorAccounts[0].legalDescription',
  'Rendition Notes': 'assessorAccounts[0].renditionNotes',
};

export const FIXEDASSETFILETOFIELD = {
  'Import status': 'status',
  'Company Name': 'companyName',
  'Company Number': 'companyNumber',
  'Property Name': 'propertyName',
  'Property Number': 'propertyNumber',
  'Legal Entity Name': 'legalEntityName',
  'Legal Entity Number': 'legalEntityNumber',
  State: 'state',
  Assessor: 'assessor',
  'Assessor Account Number': 'assessorAccountNumber',

  'Asset Number': 'number',
  'Asset Class': 'class',
  'Acquisition Date': 'acquisitionDate',
  'Tax Year': 'taxYear',
  'Alternate Asset ID': 'alternateAssetId',
  'Asset Description': 'description',
  Notes: 'notes',

  'Asset Description 2': 'fixedAsset.description2',
  'Asset GL Code / Alternate Account Number':
    'fixedAsset.glCodeAlternateAccountNumber',
  'Asset Life': 'fixedAsset.life',
  'Asset Location': 'fixedAsset.location',
  'Company Cost Center': 'fixedAsset.companyCostCenter',
  'Asset Rebook Date': 'fixedAsset.rebookDate',
  'Asset Rebook Cost': 'fixedAsset.rebookCost',
  'Historical Asset Number': 'fixedAsset.historicalNumber',
  'LTD Depreciation': 'fixedAsset.ltdDepreciation',
  Obsolescence: 'fixedAsset.obsolescence',
  'Original Assessor': 'fixedAsset.originalAssessorName',
  'Original Assessor Account Number':
    'fixedAsset.originalAssessorAccountNumber',
  'Original Cost': 'fixedAsset.originalCost',
  'Rendered Market Value': 'fixedAsset.renderedMarketValue',
  'Disposed': 'disposed',
  'Addition': 'isAddition',
};

export const INVENTORYFILETOFIELD = {
  'Import status': 'status',
  'Company Name': 'companyName',
  'Property Name': 'propertyName',
  'Property Number': 'propertyNumber',
  'Legal Entity Name': 'legalEntityName',
  'Legal Entity Number': 'legalEntityNumber',
  State: 'state',
  Assessor: 'assessor',
  'Assessor Account Number': 'assessorAccountNumber',
  'Asset Number': 'number',
  'Asset Class': 'class',
  'Assessment Date': 'acquisitionDate',
  'Tax Year': 'taxYear',
  'Alternate Asset ID': 'alternateAssetId',
  'Asset Description': 'description',
  Notes: 'notes',
  January: 'inventory.januaryAmount',
  February: 'inventory.februaryAmount',
  March: 'inventory.marchAmount',
  April: 'inventory.aprilAmount',
  May: 'inventory.mayAmount',
  June: 'inventory.juneAmount',
  July: 'inventory.julyAmount',
  August: 'inventory.augustAmount',
  September: 'inventory.septemberAmount',
  October: 'inventory.octoberAmount',
  November: 'inventory.novemberAmount',
  December: 'inventory.decemberAmount',
  'Disposed': 'disposed',
  'Addition': 'isAddition',
};

export const VEHICLEFILETOFIELD = {
  'Import status': 'status',
  'Company Name': 'companyName',
  'Property Name': 'propertyName',
  'Property Number': 'propertyNumber',
  'Legal Entity Name': 'legalEntityName',
  'Legal Entity Number': 'legalEntityNumber',
  State: 'state',
  Assessor: 'assessor',
  'Assessor Account Number': 'assessorAccountNumber',
  'Asset Number': 'number',
  'Asset Class': 'class',
  'Acquisition Date': 'acquisitionDate',
  'Tax Year': 'taxYear',
  'Alternate Asset ID': 'alternateAssetId',
  'Asset Description': 'description',
  Notes: 'notes',
  'Asset GL Code / Alternate Account Number':
    'vehicle.glCodeAlternateAccountNumber',
  'Asset Life': 'vehicle.life',
  'Company Cost Center': 'vehicle.companyCostCenter',
  'Rebook Date': 'vehicle.rebookDate',
  'Rebook Cost': 'vehicle.rebookCost',
  'Historical Asset Number': 'vehicle.historicalNumber',
  'LTD Depreciation': 'vehicle.ltdDepreciation',
  'Original Cost': 'vehicle.originalCost',
  'Rendered Market Value': 'vehicle.renderedMarketValue',
  'Vehicle Make': 'vehicle.vehicleMake',
  'Vehicle Model': 'vehicle.vehicleModel',
  'Vehicle VIN': 'vehicle.vehicleVin',
  'Vehicle Year': 'vehicle.vehicleYear',
  'Disposed': 'disposed',
  'Addition': 'isAddition',
};

export const LEASEDVEHICLEFILETOFIELD = {
  'Import status': 'status',
  'Company Name': 'companyName',
  'Property Name': 'propertyName',
  'Property Number': 'propertyNumber',
  'Legal Entity Name': 'legalEntityName',
  'Legal Entity Number': 'legalEntityNumber',
  State: 'state',
  Assessor: 'assessor',
  'Assessor Account Number': 'assessorAccountNumber',
  'Asset Number': 'number',
  'Asset Class': 'class',
  'Acquisition Date': 'acquisitionDate',
  'Tax Year': 'taxYear',
  'Alternate Asset ID': 'alternateAssetId',
  'Asset Description': 'description',
  Notes: 'notes',
  'Asset GL Code / Alternate Account Number':
    'leasedVehicle.glCodeAlternateAccountNumber',
  'Asset Life': 'leasedVehicle.life',
  'Company Cost Center': 'leasedVehicle.companyCostCenter',
  'Rebook Date': 'leasedVehicle.rebookDate',
  'Rebook Cost': 'leasedVehicle.rebookCost',
  'Lease Start Date': 'leasedVehicle.leasedStartDate',
  'Lease End Date': 'leasedVehicle.leasedEndDate',
  'Lessor Address': 'leasedVehicle.lessorAddress',
  'Lessor Name': 'leasedVehicle.lessorName',
  'LTD Depreciation': 'leasedVehicle.ltdDepreciation',
  'Monthly Rent': 'leasedVehicle.monthlyRent',
  'Original Cost': 'leasedVehicle.originalCost',
  'Rendered Market Value': 'leasedVehicle.renderedMarketValue',
  'Vehicle Make': 'leasedVehicle.vehicleMake',
  'Vehicle Model': 'leasedVehicle.vehicleModel',
  'Vehicle VIN': 'leasedVehicle.vehicleVin',
  'Vehicle Year': 'leasedVehicle.vehicleYear',
  'Disposed': 'disposed',
  'Addition': 'isAddition',
};

export const LEASEDEQUIPMENTFILETOFIELD = {
  'Import status': 'status',
  'Company Name': 'companyName',
  'Property Name': 'propertyName',
  'Property Number': 'propertyNumber',
  'Legal Entity Name': 'legalEntityName',
  'Legal Entity Number': 'legalEntityNumber',
  State: 'state',
  Assessor: 'assessor',
  'Assessor Account Number': 'assessorAccountNumber',

  'Asset Number': 'number',
  'Asset Class': 'class',
  'Acquisition Date': 'acquisitionDate',
  'Tax Year': 'taxYear',
  'Alternate Asset ID': 'alternateAssetId',
  'Asset Description': 'description',
  Notes: 'notes',

  'Asset GL Code / Alternate Account Number':
    'leasedEquipment.glCodeAlternateAccountNumber',
  'Asset Life': 'leasedEquipment.life',
  'Company Cost Center': 'leasedEquipment.companyCostCenter',
  'Rebook Date': 'leasedEquipment.rebookDate',
  'Rebook Cost': 'leasedEquipment.rebookCost',
  'Lease Start Date': 'leasedEquipment.leasedStartDate',
  'Lease End Date': 'leasedEquipment.leasedEndDate',
  'Lessor Address': 'leasedEquipment.lessorAddress',
  'Lessor Name': 'leasedEquipment.lessorName',
  'LTD Depreciation': 'leasedEquipment.ltdDepreciation',
  'Monthly Rent': 'leasedEquipment.monthlyRent',
  'Original Cost': 'leasedEquipment.originalCost',
  'Rendered Market Value': 'leasedEquipment.renderedMarketValue',
  'Disposed': 'disposed',
  'Addition': 'isAddition',
};

export const USER_FIELD_COLUMNS_KEYS = {
  'Name': 'fullName',
  'Email': 'email',
  'Role': 'role',
  'Date Added': 'createdAt',
};

export enum EUserRoles {
  SystemAdministrator = 'System Administrator',
  AccountPropertyModerator = 'Account Property Moderator',
  AccountAssetModerator = 'Account Asset Moderator',
  AccountFormModerator = 'Account Form Moderator',
  AccountReturnModerator = 'Account Return Moderator',
  AccountReportModerator = 'Account Report Moderator',
  AccountAdministrator = 'Account Administrator'
}

export const USER_ROLES = Object.values(EUserRoles);

export const FORM_STATUSES = [
  'Review required',
  'Review recommended',
  'Reviewed',
  'Unused'
];

export enum ASSET_STATUS {
  Mapping = "Mapping Needed",
  Review = "Review for Approval",
  Approved = "Approved",
  Filed = "Filed"
}

export const RETURNS_STATUSES = [
  "Mapping Needed",
  "Review Assets",
  "Approved",
  "Filed",
] as const;

export enum TOOLTIP_SECTIONS {
  PageTitle = 'Page Title',
  PageAction = 'Page Action',
  TableHeader = 'Table Header',
  TableRowAction = 'Table Row Action',
  TableAction = 'Table Action',
  AccountInfo = 'Account Info',
  Card = 'Card',
  GetStarted = 'Get Started',
  PlanSummary = 'Plan Summary',
  Help = 'Help',
  NavMenu = 'Nav Menu',
  Footer = 'Footer',
  Header = 'Header',
  Modal = 'Modal',
  Common = '',
}

export enum TOOLTIP_COMMON_KEYS {
  TableFilter = 'Filter',
  TableColumnSettings = 'Column Settings',
}

export enum DepreciationScheduleType {
  Composite = "Composite",
  NonComposite = "Non-Composite",
}

export const ASSETS_STATUSES = [
  "Mapping Needed",
  "Review for Approval",
  "Approved",
  "Filed",
] as const;
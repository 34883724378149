import numeral from 'numeral';

export function formatCurrency(value: number | string): string {
  if (value === null) {
    return '';
  }
  return `$${numeral(value).format('0,0.00')}`;
}

export function ratioFrontendToBackend(value: string): number {
  const ratioValue = parseFloat(value || '0');
  return ratioValue > 1 ? (ratioValue / 100.0) : ratioValue;
}

export function ratioBackendToFrontend(value: number): string {
  const ratioValue = value <= 1 ? value * 100.0 : value;
  return `${ratioValue}`;
}
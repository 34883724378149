import {FormikValues, useFormik} from 'formik';
import {
  at,
  cloneDeep,
  compact,
  Dictionary,
  filter,
  find,
  isEqual,
  isNil,
  map,
} from 'lodash';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import FieldDate from '../../../components/FieldDate/FieldDate';
import FieldInput from '../../../components/FieldInput/FieldInput';
import FieldSelect from '../../../components/FieldSelect/FieldSelect';
import Loader, {LoaderIndicator} from '../../../components/Loader/Loader';
import Modal from '../../../components/Modal/Modal';
import PropertiesPanel from '../../../components/PropertyPanel/PropertiesPanel';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import TooltipWrapper from '../../../components/TooltipWrapper/TooltipWrapper';
import ModalContext from '../../../context/ModalContext';
import {TOOLTIP_SECTIONS} from '../../../enums';
import {useBlocker} from '../../../hook/useBlocker';
import {FormDetail, FormQuestion, ReturnsItem} from '../../../interface';
import {
  getErrorMessage,
  getFromDetail,
  getFromQuestions,
  getReturnDetail,
  getReturns,
  saveFromAnswers,
} from '../../../services';
import style from './ManageFormDetails.module.scss';
import useSelectedSystemTaxYear from '../../../hook/useSelectedSystemTaxYear';
import { SelectOption } from '@avalara/skylab-react';

interface FormSubmitDataToSubmit {
  formQuestionId: number;
  answers: string[] | null;
  returnId?: number;
}

function ManageFormDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const {formId, returnId} = useParams();

  const [formDetail, setFormDetail] = useState<FormDetail>();
  const [returnOptions, setReturnOptions] = useState<SelectOption[]>([]);
  const [returnDetail, setReturnDetail] = useState<ReturnsItem>();
  const [formQuestions, setFormQuestions] = useState<FormQuestion[]>([]);
  const [initialValues, setInitialValues] = useState<FormSubmitDataToSubmit[]>(
    [],
  );

  const {state, dispatch} = useContext(ModalContext);
  const [blockNav, setBlockNav] = useState(false);
  const [fetchingReturnDetail, setFetchingReturnDetail] = useState(false);
  const toastRef = useRef<ToastMessageRefType | null>(null);
  const selectedSystemTaxYear = useSelectedSystemTaxYear();

  useEffect(() => {
    if (returnId) {
      setFetchingReturnDetail(true);
      getReturnDetail(returnId)
        .then((res) => {
          setReturnDetail(res.data);
          setFetchingReturnDetail(false);
        })
        .catch(() => {
          setFetchingReturnDetail(false);
        });
    } else {
      setReturnDetail(undefined);
    }
  }, [returnId]);

  const properties = useMemo(
    () => ({
      return: returnDetail
        ? {
            'Assessor Name': returnDetail.assessorAccountData.assessor.name,
            'Account Number': returnDetail.assessorAccountData.number,
            'Property Number': returnDetail.assessorAccountData.property.number,
            'Property Name': returnDetail.assessorAccountData.property.name,
            'Property location': compact(
              at(
                returnDetail.assessorAccountData.property.address,
                'line1',
                'line2',
                'state.abbreviation',
                'postalCode',
              ),
            ).join(', '),
          }
        : null,
      form: formDetail
        ? {
            State: formDetail.state?.abbreviation,
            'Tax Year': formDetail.taxYear,
            'Form Name': formDetail.name,
          }
        : null,
    }),
    [formDetail, returnDetail],
  );

  const breadcrumb = useMemo(
    () =>
      compact([
        {
          value: 'Forms',
          href: '/dashboard',
        },
        {value: 'Manage Forms', href: '/forms'},
        formDetail && {
          value: `Manage ${formDetail.name} Details`,
          href: returnDetail ? `/forms/formDetails/${formId}` : undefined,
        },
        returnDetail && {
          value: `Assessor Account ${returnDetail.assessorAccountData.number}`,
        },
      ]),
    [formDetail, returnDetail, formId],
  );

  const [fetchingFromDetail, setFetchingFromDetail] = useState(false);
  const [fetchingReturns, setFetchingReturns] = useState(false);

  useEffect(() => {
    if (formId) {
      setFetchingFromDetail(true);
      getFromDetail(formId)
        .then(({data: {form, formId: _formId, customerFormAnswers}}) => {
          setFormDetail(form ?? undefined);

          getFromQuestions(_formId)
            .then(({data: questions}) => {
              setFormQuestions(questions);

              const answers = filter(customerFormAnswers, (x) => 
                (returnId && Number(returnId) === x.returnId) || isNil(x.returnId),
              );

              const data = map(
                questions,
                (q) => {
                  let chosenAnswer = answers.find(answer => answer.formQuestionId === q.id && answer.returnId == null);
                  if (returnId) {
                    const returnAnswer = answers.find(answer => answer.formQuestionId === q.id && answer.returnId === Number(returnId));
                    if (returnAnswer) {
                      chosenAnswer = returnAnswer;
                    }
                  }
                  return ({
                    formQuestionId: q.id,
                    answers: chosenAnswer?.answers ?? null,
                    returnId: Number(returnId) || null,
                  } as FormSubmitDataToSubmit);
                }
              );

              formik.setValues(data);
              setInitialValues(data);
              setFetchingFromDetail(false);
            })
            .catch(() => {
              setFetchingFromDetail(false);
            });

          setFetchingReturns(true);
          const formattedQuery = {
            page: 1,
            perPage: Number.MAX_SAFE_INTEGER,
            taxYear: selectedSystemTaxYear.taxYear,
            statuses: "Mapping Needed,Review Assets,Approved",
            stateIds: form ? `${form.stateId}` : '',
          };
          getReturns(formattedQuery)
            .then(result => {
              const newReturnOptions: SelectOption[] = [{
                value: '',
                label: 'Default (All Assessor Accounts)'
              }];
              if (result.data?.items) {
                result.data.items.map((item: ReturnsItem) => {
                  newReturnOptions.push({
                    value: item.id.toString(),
                    label: `${item.assessorAccount.assessor.name} | ${item.assessorAccount.number}`
                  });
                });
              }
              setReturnOptions(newReturnOptions);
              setFetchingReturns(false);
            })
            .catch(() => {
              setFetchingReturns(false);
            });
      
        })
        .catch(() => {
          setFetchingFromDetail(false);
        });
    }
  }, [formId, returnId]);

  // eslint-disable-next-line
  const validate = (_formikValues: FormikValues) => {
    return undefined;
  };
  const [submitting, setSubmitting] = useState(false);

  const formik = useFormik<FormSubmitDataToSubmit[]>({
    initialValues,
    validate,
    onSubmit: (values) => {
      const errors = validate(values);
      if (!errors && formId) {
        setSubmitting(true);
        saveFromAnswers(formId, {items: values})
          .then((d) => {
            const errorMessage = getErrorMessage(d);
            if (errorMessage) {
              toastRef.current?.showErrorToast(errorMessage);
            } else {
              toastRef.current?.showSuccessToast('Save successfully');
              setBlockNav(false);
              setTimeout(goBack, 100);
            }
            setSubmitting(false);
          })
          .catch(() => {
            setSubmitting(false);
          });
      }
    },
  });

  const goBack = () => {
    (location.state as Dictionary<unknown>)?.backTrack
      ? navigate(-1)
      : navigate('/forms?tab=Form Details');
  };

  const mapToOptions = (strArray: string[] | undefined) => {
    if (strArray && strArray.length > 0) {
      return strArray.map((item) => ({
        label: item,
        name: item,
        value: item,
      }));
    }
    return [];
  };

  const getAnswerValue = (answerArray: string[] | null) => {
    if (answerArray && answerArray.length > 0) {
      return answerArray[0];
    }
    return '';
  };

  const nav = useCallback(() => {
    dispatch({type: 'OPEN', href: 'confirm-leave'});
  }, []);

  useEffect(() => {
    setBlockNav(!isEqual(initialValues, formik.values));
  }, [initialValues, formik.values]);

  useBlocker(nav, blockNav);

  const ref = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    ref.current && ref.current.blur();
  }, [state.isOpen]);

  return (
    <div className={style['main-content']}>
      {/* Breadcrumb */}
      <Breadcrumb items={breadcrumb} />
      <ToastMessage ref={toastRef} className={style['toast']} />
      <h2>
        <TooltipWrapper
          tooltipSection={TOOLTIP_SECTIONS.PageTitle}
          tooltipKey='Manage Forms Details'
        >
          <span>Manage {formDetail?.name} Details</span>
        </TooltipWrapper>
      </h2>
      <FieldSelect
        labelText='Assessor & Account Number'
        options={returnOptions}
        selectId={returnId}
        onSelect={(value) => {
          let newUrl = `/forms/formDetails/${formId}`;
          if (value?.value) {
            newUrl += `/return/${value?.value}`;
            navigate(newUrl);
          }
          else if (returnId) {
            navigate(newUrl);
          }
        }}
        classnames={style['return-select']}
        force
      />
      {properties.return && <PropertiesPanel values={properties.return} classname={'info-row'}/>}
      <form className={style['main']} onSubmit={formik.handleSubmit}>
        <div className={style['form-content']}>
          {properties.form && <PropertiesPanel values={properties.form} />}
          <div className={style['question-list']}>
            {formQuestions.map((item, index) => (
              <Fragment key={index}>
                {index > 0 && <hr />}
                <div className={style['question']}>
                  <TooltipWrapper
                    tooltipSection={TOOLTIP_SECTIONS.Common}
                    tooltipKey={item.text}
                  >
                    <div className={style['question-description']}>
                      {item.text}
                      {item?.required && (
                        <span className={style['requiredMarker']}>*</span>
                      )}
                    </div>
                  </TooltipWrapper>
                  <div className={style['answer']}>                  
                    {item.type === 'Select (Single)' && (
                      <FieldSelect
                        classnames={style['boolean-answer']}
                        labelText=''
                        options={mapToOptions(item.options)}
                        selectText={getAnswerValue(formik.values[index] ? formik.values[index].answers: null)}
                        onSelect={(selectedOption) => {
                          formik.setFieldValue(
                            `[${index}].answers`,
                            selectedOption ? [selectedOption?.name] : null,
                          );
                        }}
                      />
                    )}
                    {item.type === 'Select (Multiple)' && (
                      <div className={style['checkboxs-wrapper']}>
                        {item.options &&
                        item.options.length === 1 &&
                        item.options[0] === '' ? (
                          <CustomCheckbox
                            label={''}
                            value={
                              formik.values[index] && !!formik.values[index].answers &&
                              (formik.values[index].answers?.length || 0) > 0
                            }
                            classnames={style['checkbox-row']}
                            onChange={(value) => {
                              if (value) {
                                formik.setFieldValue(`[${index}].answers`, ['']);
                              } else {
                                formik.setFieldValue(`[${index}].answers`, null);
                              }
                            }}
                          />
                        ) : (
                          item.options?.map((option, idx) => (
                            <CustomCheckbox
                              key={idx}
                              label={option}
                              value={
                                formik.values[index] && !!formik.values[index].answers &&
                                (
                                  formik.values[index].answers as string[]
                                ).indexOf(option) >= 0
                              }
                              classnames={style['checkbox-row']}
                              onChange={(value) => {
                                let res: string[] = cloneDeep(
                                  formik.values[index] && formik.values[index].answers || [],
                                );
                                if (res.length === 1 && res[0] === '') {
                                  res = [];
                                }
                                if (value) {
                                  res.push(option);
                                  formik.setFieldValue(`[${index}].answers`, res);
                                } else {
                                  const _idx = res.indexOf(option);
                                  res.splice(_idx, 1);
                                  formik.setFieldValue(
                                    `[${index}].answers`,
                                    res.length === 0 ? null : res,
                                  );
                                }
                              }}
                            />
                          ))
                        )}
                      </div>
                    )}
                    {item.type === 'Text' && (
                      <FieldInput
                        classnames={style['text-answer']}
                        labelText=''
                        placeholder='Enter'
                        value={getAnswerValue(formik.values[index] ? formik.values[index].answers: null)}
                        onChange={(newVal) => {
                          formik.setFieldValue(
                            `[${index}].answers`,
                            newVal ? [newVal] : null,
                          );
                        }}
                      />
                    )}
                    {item.type === 'Date' && (
                      <FieldDate                      
                        classnames={style['date-answer']}
                        labelText=''
                        selectValue={getAnswerValue(formik.values[index] ? formik.values[index].answers: null)}
                        onSelect={(value) => {
                          formik.setFieldValue(
                            `[${index}].answers`,
                            value ? [value] : null,
                          );
                        }}                    
                    />
                    )}
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
        <div className={style['bottom-bar']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.Common}
            tooltipKey='Required field'
          >
            <span className={style['required']}>* Required field</span>
          </TooltipWrapper>
        </div>
        <div className={style['buttons']}>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Save and complete'
          >
            <button
              className='primary'
              type='submit'
              disabled={submitting || !formik.dirty || !formik.isValid}
            >
              Save and complete
              {submitting ? (
                <LoaderIndicator className='button-loading' loading={true} />
              ) : null}
            </button>
          </TooltipWrapper>
          <TooltipWrapper
            tooltipSection={TOOLTIP_SECTIONS.PageAction}
            tooltipKey='Cancel'
          >
            <button className='secondary' type='button' onClick={goBack}>
              Cancel
            </button>
          </TooltipWrapper>
        </div>
      </form>
      <Modal
        title='Confirmation'
        body={<p>There are unsaved changes, do you want to discard?</p>}
        isOpen={state.isOpen && state.href === 'confirm-leave'}
        footer={
          <div className='buttons'>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Confirm'
            >
              <button
                className='primary'
                onClick={() => {
                  setBlockNav(false);
                  dispatch({type: 'CLOSE'});
                  setTimeout(goBack, 100);
                }}
                ref={ref}
              >
                Confirm
              </button>
            </TooltipWrapper>
            <TooltipWrapper
              tooltipSection={TOOLTIP_SECTIONS.Modal}
              tooltipKey='Confirmation Cancel'
            >
              <button
                className='secondary'
                onClick={() => dispatch({type: 'CLOSE'})}
              >
                Cancel
              </button>
            </TooltipWrapper>
          </div>
        }
      />

      <Loader isOpen={fetchingFromDetail || fetchingReturnDetail || fetchingReturns} />
    </div>
  );
}

export default ManageFormDetails;
